'use client'

import { useState } from 'react'

import { Card, CardContent, Container } from '@mui/material'

import { useTranslations } from 'next-intl'

import { EmailSent, LoginForm } from 'features/auth/components'

const LoginPage = (): React.JSX.Element => {
  const [loginSuccess, setLoginSuccess] = useState(false)
  const t = useTranslations()

  return (
    <>
      <title>{t('login')}</title>
      <Container component="main" maxWidth="xs" sx={{ mt: 10 }}>
        <Card variant="outlined">
          <CardContent>
            {loginSuccess && <EmailSent />}
            {!loginSuccess && <LoginForm onSuccess={() => setLoginSuccess(true)} />}
          </CardContent>
        </Card>
      </Container>
    </>
  )
}

export default LoginPage
